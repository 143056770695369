<template>
  <div class="">
    <main-nav></main-nav>
    <div class="my-page my-page-top-padded theme-font blog-text-very-dark-gray">

      <div class="article my-text-container">

        <h1 class="article-title">
          Best Way to Gift Wrap an Online Order Delivered Directly to Your Recipient
        </h1>
        <h2 class="article-description blog-text-dark-gray">
          Gift wrapping your online orders is now easier than ever with Swipewrap! Send a swipewrap, then let your gifted items arrive later via traditional mail!
        </h2>

        <div class="">
          <img src="/images/stock/girl-getting-package-with-swipewrap.jpg" alt="Get a swipewrap, then receive your gift package separately!">
        </div>

        <div class="">
        You’ve been shopping online and you’ve found the perfect gift that you want to have delivered to a friend or loved one.
        </div>
        <div class="">
        Well done! Finding the right gift isn’t always easy, go you!
        </div>
        <div class="">
        But, wait, how do you wrap it?
        </div>
        <div class="">
        You could deliver the package to yourself at your own address, wrap it yourself, and then personally ship it to your intended recipient via a postal service. But, that means you’ll have to <b>pay twice</b> for shipping and the shipping time could be potentially <b>twice as long since</b> you have to ship it again!
        </div>
        <div class="">
        Alternatively, some e-commerce websites come with an “add gift wrap” option where warehouses will wrap your package with a <b>generic</b>, run-of-the-mill gift wrap that everyone knows is going out to millions of people. But, that option simply conveys “unremarkable” to your intended recipient.
        </div>
        <div class="">
        Wouldn’t it be nice if there was a way to avoid the extra hassle of spending twice the time and money just to wrap a package, while also not facing the issue of ending up with a generic gift wrap that isn’t special?
        </div>
        <div class="">
          Luckily, there is a way! Now, you can send a swipewrap!
        </div>
        <div class="">
        <img src="/images/stock/man-opening-gift.jpg" alt="Man unwrapping a swipewrap">
        </div>
        <div class="">
        Swipewrap is an online service that lets you wrap a 3D virtual box online, then send it electronically to any phone, tablet or laptop. By sending a swipewrap, you can give that same fun, personal experience of unwrapping a gift, but with the instant convenience of being digital. Meanwhile, your gifted items arrive separately.
        </div>
        <!-- <div class="">
        [image of upload]
        </div> -->
        <div class="">
        Upload a picture representing your gift, and either send your swipewrap right away if your gift is last-minute, or schedule your swipewrap to be sent via email at a specific time/date. Then, rather than unwrapping a physical box, your recipient will unwrap their swipewrap that you made specially just for them.
        </div>
        <div class="">
          <img src="/images/stock/people-having-fun-with-swipewrap.jpg" alt="People having fun unwrapping a swipewrap">
        </div>
        <div class="">
        Swipewrap is fun for both gift senders and gift recipients, and people of all ages love it!
        </div>
        <div class="">
        Make someone feel special by sending them a swipewrap today!
        </div>

      </div>

      <blog-footer></blog-footer>
    </div>
  </div>
</template>

<script>
  import MainNav from '@/components/navs/MainNav.vue';
  import BlogFooter from '@/components/blog/BlogFooter.vue';
  export default {
    components: {
      MainNav,
      BlogFooter,
    },
  };
</script>
